* {
  box-sizing: border-box;
}

body.modal-active {
  overflow: hidden;
}

#modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 1;
}

#modal-container.two {
  transform: scale(1);
}

#modal-container.two .modal-background {
  background: rgba(0, 0, 0, .0);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.two .modal-background .custom-modal {
  opacity: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.two.out {
  animation: quickScaleDown 0s 0.5s linear forwards;
}

#modal-container.two.out .modal-background {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.two.out .modal-background .custom-modal {
  animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.two.out + .content {
  animation: scaleForward 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container .modal-background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 모달 안 박스*/
#modal-container .modal-background .custom-modal {
  background-color: #1c1c1e;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  font-weight: 300;

}


/* 모달 안 내용*/
#modal-container .modal-background .custom-modal h2 {
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 15px;
}

#modal-container .modal-background .custom-modal p {
  font-size: 18px;
  line-height: 22px;
}

.custom-modal .content {
  margin: auto 0;
  line-height: 1.63;
  text-align: center;
  padding: 0 1rem;
}

.custom-modal .confirm {
  height: 3.75rem;
  background-color: #f26f20;
  border-radius: 0 0 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}


@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, .0);
  }
  100% {
    background: rgba(0, 0, 0, .7);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, .7);
  }
  100% {
    background: rgba(0, 0, 0, .0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
}

@keyframes scaleBack {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.85);
  }
}

@keyframes scaleForward {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }
  99.9% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}





